const LINKS_LIST = [
   {
      image: "https://backup.vadapega.art/motorroach/aboutt/logoSocial.png",
      link: "https://vadapega.carrd.co/",
      label: "Social Media",
   },
   {
      image: "https://backup.vadapega.art/images/icons/logopatreon.png",
      link: "https://patreon.com/VAdaPEGA",
      label: "Patreon",
   },
   {
      image: "https://backup.vadapega.art/images/icons/logokofi.png",
      link: "https://ko-fi.com/vadapega",
      label: "Ko-fi",
   },

];

export default LINKS_LIST;